<template>
  <main ict class="prod">
    <sub-menu />
    <p-section class="hero">
      <div class="holder" v-prx:sticky>
        <div class="bg" v-prx:progress="{ r:[3,6], o:[0,1] }"></div>
        <h2 v-prx:progress="{ r:[3,6], o:[1,0] }">ICT 카트리지</h2>
        <p v-prx:progress="{ r:[3,6], o:[1,0] }">스마트한 공간 제어</p>
        <div class="img" v-prx:progress="{
            MS: { r:[4,10], ty:[0,-180], o:[1,0] },
            TL: { r:[4,10], ty:[0,-500], o:[1,0] }
        }">
          <img src="/img/cartridge/ict-hero.png" alt="" >
          <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
        </div>
        <div class="overview" v-prx:progress="{ r:[6,10], o:[0,1], ty:[80,0] }">
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">ICT 카트리지</p>
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">
            FIT의 모든 시스템은 통합 운영됩니다. ICT 카트리지는 클라우드의 명령을 다양한 시스템에 신속하고 정확하게 전달합니다. 모바일 제어와 시스템 연계 운영을 통해 효율적으로 공간을 최적화할 수 있습니다.</p>
        </div>
      </div>
    </p-section>
    <p-section class="feature">
      <div class="holder" v-prx:sticky>
        <ul v-prx:progress="{ r:[1,11], pan:[0,100] }">
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/ict-feature-ico-1.svg" alt="" class="ico">
              <p>완벽한 <br>클라우드 연동</p>
              <p>모든 시스템은 하나의 클라우드로 연결되어 효율적인 정보 수집과 제어를 가능하게 합니다.</p>
              <div class="video">
                <video src="/img/cartridge/ict-feature-video-1.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/ict-feature-video-1.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/ict-feature-ico-2.svg" alt="" class="ico">
              <p>스마트한 <br>연계 운영</p>
              <p>ICT 카트리지는 다른 카트리지와 협업하여 사용자 맞춤형 환경을 제공하고, 공간 환경을 최적화합니다.</p>
              <div class="video col-2">
                <video src="/img/cartridge/ict-feature-video-21.mp4" autoplay muted playsinline loop></video>
                <video src="/img/cartridge/ict-feature-video-22.mp4" autoplay muted playsinline loop></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/ict-feature-ico-3.svg" alt="" class="ico">
              <p>손쉬운 <br>모바일 제어</p>
              <p>사용자는 실내환경을 직접 제어할 수 있습니다. 모바일 기기를 통해 터치 한 번으로 사용자 맞춤 공간이 마련됩니다.</p>
              <div class="video">
                <video src="/img/cartridge/ict-feature-video-3.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/ict-feature-video-3.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </p-section>
    <p-section class="structure">
      <div class="holder" v-prx:sticky>
        <h3>간편한 <br class="hidden-tp-up">설치와 교체</h3>
        <img src="/img/cartridge/ict-structure.s.png" alt="" class="hidden-tl-up">
        <img src="/img/cartridge/ict-structure.l.png" alt="" class="hidden-tp-down">
        <dl>
          <dt>① 외부 프레임_옵션1</dt>
          <dd>깃털 타입 적용</dd>
          <dt>② 외부 프레임_옵션2</dt>
          <dd>글래스 타입 적용</dd>
          <dt>③ 공조 카트리지</dt>
          <dd>냉난방 환기 시스템</dd>
          <dt>④ FIT 프레임</dt>
          <dd>내부 스킨을 포함한 외장 함체</dd>
          <dt>⑤ 가이드 프레임</dt>
          <dd>FIT 프레임 지지/기반</dd>
        </dl>
        <p>직관적인 구조를 통해 카트리지를 간편하게 설치하고, 손쉽게 교체할 수 있습니다. 예기치 않은 변화와 다양한 요구에 유연하게 대응합니다.</p>
      </div>
    </p-section>
    <section class="spec">
      <div class="inner-wrap">
        <h3>제품 사양</h3>
        <ul>
          <li>
            <img src="/img/cartridge/ict-spec.png">
            <p>ICT 카트리지</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 3,150mm</dd>
              <dd><b>너비</b> 350mm</dd>
              <dd><b>깊이</b> 270mm</dd>
              <dd><b>무게</b> 100kg</dd>
            </dl>
            <dl>
              <dt>성능</dt>
              <dd><b>연산장치 및 저장장치</b> Dual core Cortex-A Series ARM Processor <br>1GB DDR3 SDRAM <br>Removable micro-SD card with 4GB flash storage / 2GB user storage</dd>
              <dd><b>통신 및 제어 관제점</b> 16 universal inputs, 4 digital input, 8 digital outs, 8 analog outputs <br>2 isolated 385 serial ports <br>2 1/100MB Ethernet ports <br>HDMI 1.4 (Standard ports) <br>2 USB ports (touchscreen, mouse, keyboard support) <br>BACnet MS/TP, BACnet TCP/IP, MODBUS RTU, MDOBUS TCP/IP Support</dd>
              <dd><b>다양한 카트리지 연동 / 통합 구축</b> 공조 카트리지 및 FTU 제어, 전동 루버 연동 <br>유무선 네트워크 연동, 무선 LED 조명 연동 <br>다양한 3rd Party 제어 설비 연동</dd>
            </dl>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>
<script>

import SubMenu from '@/views/common/SubMenu.vue';

export default {
  name: 'CartridgeSkin',
  components: { SubMenu },
  metaInfo() {
    return {
      title: 'FIT Cartridge',
      meta: [
        { vmid: 'title', content: 'FIT Cartridge', },
        { vmid: 'description', content: 'FIT Cartridge는 예기치 않은 변화와 다양한 요구에 유연하게 대응하며 다양한 카트리지 디자인을 통해 건물의 아이덴티티 제공합니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
@import "~@/less/prod.less";

[ict].prod {
  .hero {
    img { .w(154); }
  }
}

@media (min-width: @screen-tp-min) {
  [ict].prod {
    .hero {
      img { .w(168); }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [ict].prod {
    .hero {
      img { .w(232); }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [ict].prod {
    .hero {
      img { .w(254); }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [ict].prod {
    .hero {
      img { .w(270); }
    }
  }
}
</style>
